import * as React from 'react';
import TopNav from "./TopNav";
import { useParams, Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PinDropIcon from "@mui/icons-material/PinDrop";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from '@mui/system';

import {useState} from "react";
import { milling_data, spread_rate } from './mData';
import BasicTable, { createData } from './DataDisplay';
import  SelectTextFields from "./dropDowns"

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import TextInput from "./TextInput";

import {
    MainBody,
    PRIMARY_GREEN,
    PrimaryButton,
    SecondaryButton,
    OtherButton,
    BLACK
  } from "./StyledComponents";
  import { blue } from '@mui/material/colors';


  export default function MillingFeature()  {

    const [map, setMap] = useState("");
    const [tons, setTons] = useState(1);
    const [yards, setYards] = useState(1);
    const [depth, setDepth] = useState(1.5);
    const [station, setStation] = useState(1);
    const [length, setLength] = useState(1);
    const [width, setWidth] = useState(1);
    const [fill, setFill] = useState("material");
    const [location, setLocation] = useState("Hwy 555");
    const [patched, setPatched] = useState("No");
    const [note, setNote] = useState("LT");

     // use state variables for spread rate, per inch and total (div by depth and normal)
  const [sr, setSr] = useState(100);
  const [sr2, setSr2] = useState(sr*depth);


  // tons estimator use state variables 

  const [etons, setEtons] = useState(5000);


  //ayards are allotted yards 
  const [ayards, setAyards] = useState(tons*2000/sr2);
  //area
  const [area, setArea] = useState(length*width); 

  //for tracking the running total of tons 
  const [totaltons, setTotaltons] = useState(tons);
  //and the total area
  const [tarea, setTarea] = useState(0);



  function handleSpread() {
    setSr(spread_rate[0]);
    //console.log(sr);
    //console.log(depth);
    //console.log(sr * depth);
    setSr2(sr * depth);
   // console.log("C H E C K IF THIS FUNCTION IS W O R K I N G");

  }


  function handleClick() {
    setArea(Math.round(length * width));
    setEtons(Math.round(sr2*area/(2000*9)));
    setAyards(Math.round(tons * 2000/sr2));
    setTotaltons(totaltons + tons);
    

    setFill(fill);
    console.log(fill);
    milling_data.push(createData(map,location,note,station,length,width,area,depth,ayards,tons,patched));


    //alert("clcie");
  }
  function handleCalc() {
    setSr(spread_rate[0]);
    setSr2(Math.round(sr * depth));
    setArea(Math.round(length * width));
    setEtons(Math.round(sr2*area/(2000*9)));
    setAyards(Math.round(tons * 2000/sr2));
    setTarea(tarea + area);
   
  }


    return (
        <>
          <TopNav />
          <MainBody>
            <Box sx={{width: "80%"}}>
                <Typography 
                 bottomGutter
                 variant="h5"
                 component="div"
                 sx={{marginBottom:"30px"}}
                >
                Milling Estimator
                </Typography>
                <Divider />
                <Box sx={{padding: "20px 0"}}>
                    <Typography
                    sx={{color: PRIMARY_GREEN}}
                    variant="subtitle2"
                    gutterBottomcomponent="div"
                    >
                    Put Tool Description Here Later
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom compoentent="div">
                      <img  
                        alt="location"
                        src="https://assets.codepen.io/6060109/location-icon.png"
                        style={{height: "13px",marginRight: "5px"}}
                        />

                    Location will display Here
                    </Typography>
                </Box>
                <Divider />
                <h3>Enter Information Below</h3>
                <Box 
                sx = {{m:5, border: 1}}
                >
                    <Box className="top-mil-box"
                    sx = {{border: 0, width: "100%"}}
                    
                    >
                    
                    <div className ="side-Mill">
                    <h4>Milling Depth</h4>
                    <TextInput updateValue={setDepth}/>
                    <h4>Alloted Tons</h4>
                    <TextInput updateValue={setTons} placeholder={ayards*sr2/2000}/>
                    <h4>Square Yards</h4>
                    <TextInput updateValue={setAyards} placeholder={Math.round((tons*2000)/sr2)}/>
                    <h4>Choose Material</h4>
                    </div>
                    <div className="side-Mill" id="d2">
                        <h4>Running Total (tons)</h4>
                        <Typography>
                            {totaltons}
                        </Typography>
                        <h4>Allotment Used</h4>
                        <Typography>
                            X tons / Total 
                        </Typography>
                        <h4>Total Area (SQ Yards)</h4>
                        <Typography>{area}</Typography>
                        <h4>Total Volume (Cubic Yards)</h4>
                    </div>
                    
                    <div
                    onClick={handleSpread}
                    >
                        <SelectTextFields />

                    </div>
                    </Box>
                    
                   
                    <Box
                    sx = {{borderTop: 0, borderColor: BLACK}}
                    >
                    <p
                    onChange = {(sr) => setSr2(sr)}
                    >Spread Rate Per Inch: {sr}
                    (lb/sy/in)
                    </p>
                    </Box>
                    <Box
                    sx = {{border: 1, borderColor: BLACK}}
                    >
                    <p
                    >Spread Rate: {sr2}
                    (lb/sy/in)
                    </p>
                    </Box>
                    
                    <h4>Map Number</h4>
                    <TextInput updateValue={setMap} />
                    <h4>Name</h4>
                    <TextInput/>
                    <h4>Station Number</h4>
                    <TextInput updateValue={setStation} />
                    <h4>Length</h4>  
                    <TextInput updateValue={setLength} />
                    <h4>Width</h4>
                    <TextInput updateValue={setWidth} />
                    <h4>Location</h4>
                    <TextInput updateValue={setLocation} />
                    <h4>Notes</h4>
                    <TextInput updateValue={setNote} />
                    <h4>Patched?</h4>
                    <TextInput updateValue={setPatched} />
          
                </Box>
                <PrimaryButton
                     sx={{
                        textTransform: "none",
                        fontWeight: 400,
                        fontSize: "12px",
                        width: "100%",
                        maxWidth: 300,
                        m: 2
                    }}
                    onClick={handleClick}
                 >
                  Add Data 
              </PrimaryButton>
             <PrimaryButton
                  sx={{
                      textTransform: "none",
                      fontWeight: 400,
                      fontSize: "12px",
                      width: "100%",
                     maxWidth: 300,
                    bgcolor: PRIMARY_GREEN 
                     }}
                    onClick={handleCalc}
        >
                 Calculate 
            </PrimaryButton>

             <Divider />
         <Box>
           <BasicTable/>
        </Box>










        </Box>
            







          </MainBody>
        </>






    );







  }