import { useState } from "react";

import TopNav from "./TopNav";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import CategoryMenu from "./CategoryMenu";
import BodySearch from "./BodySearch";
import JobList from "./ToolList";
import { MainBody, HeroBody, FooterBody } from "./StyledComponents";
import {Divider} from "@mui/material";
import { bgcolor } from "@mui/system";



export default function LandingPage() {
    return (
        <>
        <TopNav />
          <HeroBody sx={{width: "100%", display: "flex", justifyContent: "space-around"}}>
               
        <div className="hero-section">
            <h2>We Do Data Right</h2>

        </div>
       
        
          </HeroBody>
          <FooterBody>
            <div className="sales-copy-bs">
                <h2>Our Services</h2>
                <div className="block-thing">
                    <img src="https://www.pbctoday.co.uk/news/wp-content/uploads/2019/06/big-data-2.jpg"/>
                    <p>Calculations</p>
                </div>
                <div className="block-thing">
                    <img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fc4.wallpaperflare.com%2Fwallpaper%2F890%2F539%2F769%2Fmathematics-equations-science-3d-wallpaper-preview.jpg&f=1&nofb=1&ipt=e8681564f45c0eaec2d1378d610a907ad2609d7ca65bc8cbff8278cf44ac155c&ipo=images"/>
                    <p>Clerical Task Automation</p>
                </div>
                <div className="block-thing">
                    <img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fc.pxhere.com%2Fimages%2F94%2F4c%2Fe8df4e75da76e5d18ec73ce3f1aa-1575607.jpg!d&f=1&nofb=1&ipt=7dc1dded9111edf3f8ec4e579f85afddb41e23c75ba5eb737eb3a9de2da04d3f&ipo=images"/>
                    <p>Data Indexing</p>
                </div>
                <div className="sub-block">
                
                
                </div>
            </div>


          </FooterBody>
        </>
      );
}